import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'antd';

import Layout from '../components/layout';
import SEO from '../components/seo';
import VideoBackgroundHeader from '../components/home-page/VideoBackgroundHeader';
import CapabilityStatementComponent from '../components/home-page/CapabilityStatementComponent';
import CareersSectionComponent from '../components/home-page/CareersSectionComponent';
import ClientsPartialListComponent from '../components/home-page/ClientsPartialListComponent';
import ProjectPropertiesComponent from '../components/home-page/ProjectPropertiesComponent';
import RadarWebinarComponent from '../components/home-page/RadarWebinarComponent';
import ListYourPropertyComponent from '../components/home-page/ListYourPropertyComponent';
import BusinessDistrictComponent from '../components/home-page/BusinessDistrictComponent';
import IndustryInsightsComponent from '../components/home-page/IndustryInsightsComponent';
import ResearchPublicationComponent from '../components/home-page/ResearchPublicationComponent';
import UpComingEventsComponent from '../components/home-page/UpComingEventsComponent';
import FeaturedHighlightComponent from '../components/home-page/FeaturedHighlightComponent';

const IndexPageWrapper = styled.div`
  padding-top: 89px;
  overflow: hidden;
`;

function IndexPage({ location }) {
  const data = useStaticQuery(graphql`
    {
      allStrapiRealEstateInsight(
        limit: 5
        sort: { order: DESC, fields: published_date }
      ) {
        nodes {
          author
          description
          strapiId
          url_link
          title
          published_date(formatString: "MMMM DD, YYYY")
        }
      }

      allStrapiProjectListing(
        sort: { fields: priority, order: ASC }
        filter: { visibility: { eq: true } }
      ) {
        nodes {
          status
          banner
          strapiId
          thumb
          url_link
          visibility
          propertyname
          category
          classification
          location
        }
      }

      allStrapiPublications(
        limit: 4
        sort: { order: DESC, fields: publish_date }
      ) {
        nodes {
          url_path
          title
          strapiId
          publish_date(formatString: "MMMM DD, YYYY")
          description
          image_path
        }
      }
    }
  `);

  return (
    <Layout source="Home Page">
      <SEO
        title="The Leading Commercial Real Estate Consultancy Firm in the Philippines"
        description="The Leading Commercial Real Estate Consultancy Firm in the Philippines."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />
      <IndexPageWrapper>
        <VideoBackgroundHeader />
        <CapabilityStatementComponent />

        <Row
          type="flex"
          justify="center"
          style={{
            backgroundColor: '#f2f2f2',
            marginBottom: 50,
            padding: '50px 0px',
          }}
        >
          <Col
            xs={23}
            sm={23}
            md={23}
            lg={23}
            xl={22}
            xxl={20}
            style={{ marginBottom: 25 }}
          >
            <ResearchPublicationComponent
              publications={data.allStrapiPublications.nodes}
            />
          </Col>

          <Col xs={23} sm={23} md={23} lg={23} xl={22} xxl={20}>
            <Row gutter={[20, 20]} type="flex" justify="center">
              <Col xs={23} sm={23} md={14} lg={16} xl={16} xxl={16}>
                <IndustryInsightsComponent
                  blogs={data.allStrapiRealEstateInsight.nodes}
                />
              </Col>
              <Col xs={23} sm={23} md={10} lg={8} xl={8} xxl={8}>
                <UpComingEventsComponent />
                <FeaturedHighlightComponent />
              </Col>
            </Row>
          </Col>
        </Row>

        <RadarWebinarComponent />

        <ProjectPropertiesComponent
          title={
            <h2>
              <span>Key Office</span> Building Projects
            </h2>
          }
          description={
            <p>
              We offer wide range of properties that suit every - need from
              government and private companies, big or small, local or
              international.
            </p>
          }
          properties={data.allStrapiProjectListing.nodes.filter(
            keyProject => keyProject.category === 'OFFICE'
          )}
          externalLink={{
            url: 'https://offices.primephilippines.com/',
            label: (
              <span>
                VIEW OTHER OFFICE SPACES{'  '}
                <FontAwesomeIcon icon="angle-right" />
              </span>
            ),
          }}
          padding="50px 0px 0px"
        />

        <ProjectPropertiesComponent
          title={
            <h2>
              <span>Key Retail</span> Building Projects and Commercial Lots
            </h2>
          }
          description={
            <p>
              {`From commercial spaces to commercial lots, ranging from big to
              small, there's a PRIME property that can accomodate new and
              existing businesses.`}
            </p>
          }
          properties={data.allStrapiProjectListing.nodes.filter(
            keyProject => keyProject.category === 'RETAIL'
          )}
          externalLink={{
            url: 'https://commercialproperties.primephilippines.com/',
            label: (
              <span>
                VIEW OTHER COMMERCIAL SPACES{'  '}
                <FontAwesomeIcon icon="angle-right" />
              </span>
            ),
          }}
          padding="50px 0px"
        />

        <ListYourPropertyComponent />

        <ProjectPropertiesComponent
          title={
            <h2>
              <span>Key Industrial</span> Projects and Warehouses
            </h2>
          }
          description={
            <p>
              Get your industrial real estate requirement satisfied in any major
              industrial hub in the Philippines.
            </p>
          }
          properties={data.allStrapiProjectListing.nodes.filter(
            keyProject => keyProject.category === 'INDUSTRIAL'
          )}
          externalLink={{
            url: 'https://commercialproperties.primephilippines.com/',
            label: (
              <span>
                VIEW OTHER INDUSTRIAL PROPERTIES{'  '}
                <FontAwesomeIcon icon="angle-right" />
              </span>
            ),
          }}
          padding="50px 0px"
        />

        <BusinessDistrictComponent
          title={
            <h2>
              <span>Business District</span> in the Philippines
            </h2>
          }
          description={
            <p>
              Our dedicated teams across multiple offices in the Philippines can
              guide your entry into any business district in the country.
            </p>
          }
          padding="50px 0px"
        />

        <ClientsPartialListComponent />

        <CareersSectionComponent />
      </IndexPageWrapper>
    </Layout>
  );
}

IndexPage.propTypes = {
  location: PropTypes.object,
};

export default IndexPage;
