  import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Divider } from 'antd';
import CountUp from 'react-countup';

const StyledDivWrapper = styled.div`
  padding: 100px 0px;
  @media only screen and (max-width: 426px) {
    h1 {
      font-size: 2rem;
    }
  }
`;

const StyledCapabilityStatementWrapper = styled.div`
  .capability-description {
    text-align: center;
    font-size: 1.2em;
    line-height: 1.4em;
    span {
      font-weight: bold;
    }
  }
  .capability-title {
    text-align: center;
    h1 {
      font-size: 36px;
    }
    span {
      font-weight: 600;
      color: ${props => props.theme.primeBluePalette.original};
      font-family: 'Poppins', 'Proxima Nova', 'Open Sans', san-serif;
    }
  }
  .capability-values {
    text-align: center;
    h2 {
      color: ${props => props.theme.primeBluePalette.original};
      margin-bottom: 0px;
      font-size: 2rem;
      line-height: 2rem;

      @media only screen and (max-width: 426px) {
        font-size: 1.85em;
        line-height: 1.85rem;
      }
    }

    p {
      margin-bottom: 0px;
      font-size: 1rem;
      line-height: 1rem;
    }
  }
`;

function CapabilityStatementComponent() {
  const [isStarted, setIsStarted] = useState(false);
  const transacetionsCounterRef = useRef(null);
  const dealsCounterRef = useRef(null);
  const countriesCounterRef = useRef(null);
  const employeesCounterRef = useRef(null);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (!isStarted) {
      setIsStarted(true);
      transacetionsCounterRef.current.start();
      dealsCounterRef.current.start();
      countriesCounterRef.current.start();
      employeesCounterRef.current.start();
    }
  }, [isStarted]);

  return (
    <StyledDivWrapper ref={wrapperRef}>
      <StyledCapabilityStatementWrapper>
        <Row type="inline-flex" justify="center">
          <Col xs={22} sm={22} md={18} lg={20} xl={18} xxl={14}>
            <Row className="capability-values" gutter={[10, 10]} align="middle">
              <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                <CountUp ref={dealsCounterRef} start={0} end={400} duration={3}>
                  {({ countUpRef }) => (
                    <h2 className="value">
                      USD <span ref={countUpRef} />
                      M+
                    </h2>
                  )}
                </CountUp>
                <p>worth of deals transacted</p>
              </Col>
              <Col xs={8} sm={8} md={8} lg={6} xl={6} xxl={6}>
                <CountUp
                  ref={transacetionsCounterRef}
                  start={0}
                  end={4000}
                  duration={3}
                >
                  {({ countUpRef }) => (
                    <h2 className="value">
                      <span ref={countUpRef} />+
                    </h2>
                  )}
                </CountUp>
                <p>clients served</p>
              </Col>
              <Col xs={8} sm={8} md={8} lg={6} xl={6} xxl={6}>
                <CountUp
                  ref={countriesCounterRef}
                  start={0}
                  end={200}
                  duration={4}
                >
                  {({ countUpRef }) => (
                    <h2 className="value">
                      <span ref={countUpRef} />+
                    </h2>
                  )}
                </CountUp>
                <p>real estate projects</p>
              </Col>
              <Col xs={8} sm={8} md={8} lg={6} xl={6} xxl={6}>
                <CountUp
                  ref={employeesCounterRef}
                  start={0}
                  end={100}
                  duration={4}
                >
                  {({ countUpRef }) => (
                    <h2 className="value">
                      <span ref={countUpRef} />+
                    </h2>
                  )}
                </CountUp>
                <p>research studies done</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row type="inline-flex" justify="center">
          <Col xs={22} sm={22} md={18} lg={20} xl={18} xxl={14}>
            <Divider
              style={{
                borderTopColor: '#212121CC',
                borderTopWidth: 2,
                marginTop: '8px'
              }}
            />
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col
            xs={22}
            sm={22}
            md={18}
            lg={20}
            xl={18}
            xxl={14}
            className="capability-title"
          >
            <h1>
              An <span>award-winning</span> & recognized leader in the
              Philippine commercial real estate service industry.
            </h1>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col
            xs={22}
            sm={22}
            md={18}
            lg={11}
            xl={11}
            xxl={11}
            className="capability-description"
          >
            Entrust your real estate service needs to a company well-equipped to
            deal with today's modern real estate business, with offices in{' '}
            <span>Manila</span>, <span>Davao</span>, and <span>Cebu</span>.
          </Col>
        </Row>
      </StyledCapabilityStatementWrapper>
    </StyledDivWrapper>
  );
}

export default CapabilityStatementComponent;
