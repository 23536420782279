import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';

const CareersSectionWrapper = styled.div`
  max-width: 1440px;
  margin: auto;

  background: url('../../images/home/careers-secction-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  color: ${props => props.theme.whitePalette.original};

  > div {
    padding: 50px 0px;
    background-color: ${props => `${props.theme.primeBluePalette.original}CC`};
    display: flex;
    align-content: center;
  }

  h2 {
    font-size: 3em;
    @media only screen and (max-width: 426px) {
      height: initial;
      font-size: 1.75em;
    }
  }

  h4 {
    color: ${props => props.theme.primeYellowPalette.original};
  }

  .ant-btn-primary {
    color: ${props => props.theme.primeBluePalette.original};
    background: ${props => props.theme.primeYellowPalette.original};
    border-color: ${props => props.theme.primeYellowPalette.original};
  }
`;

function CareersSectionComponent() {
  return (
    <CareersSectionWrapper>
      <div>
        <Row type="flex" justify="center">
          <Col
            xs={{ span: 21 }}
            sm={{ span: 21 }}
            md={{ span: 22 }}
            lg={{ span: 20 }}
            xl={{ span: 18 }}
            xxl={{ span: 20 }}
          >
            <h2>Be part of a company that invest in you and your career.</h2>
            <br />

            <Row gutter={10}>
              <Col xs={24} sm={24} md={10} lg={9} xl={8}>
                <h4>PROFESSIONALS</h4>
                <p>
                  Enhance your career with a company that invests in you.
                  Experience the different culture we have.
                </p>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={10}
                lg={{ span: 9, offset: 1 }}
                xl={{ span: 8, offset: 1 }}
              >
                <h4>INTERNSHIPS</h4>
                <p>
                  Develop your skills with hands-on industry experience and a
                  dynamic team of professional mentors.
                </p>
              </Col>
            </Row>

            <br />
            <div>
              <Button type="primary" href="/careers" size="large">
                Take me there
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </CareersSectionWrapper>
  );
}

export default CareersSectionComponent;
