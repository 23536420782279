import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Row, Col, Button } from 'antd';

import primeRadarLogo from '../../../static/images/home/prime-radar-logo.png';

const StyledDivWrapper = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  .radar-wrapper {
    padding: 20px 0px;
    position: relative;
    background-color: ${props => `${props.theme.blackPalette.original}8f`};
    color: white;
    overflow: hidden;
    :after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('../../images/home/radar-bg.jpg');
    }

    .btn-view-sched {
      color: ${props => props.theme.primeBluePalette.original};
      background: ${props => props.theme.primeYellowPalette.original};
      border-color: ${props => props.theme.primeYellowPalette.original};
    }

    .radar-cta {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .radar-logo {
      width: 100%;
      max-width: 350px;
      height: auto;
      margin-bottom: 5px;
      @media only screen and (max-width: 576px) {
        max-width: 300px;
      }
    }
    .radar-desc {
      text-align: left;
      @media only screen and (max-width: 576px) {
        text-align: center;
      }

      .banner-description {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`;

function RadarWebinarComponent() {
  return (
    <StyledDivWrapper>
      <div className="list-your-property">
        <Row className="radar-wrapper" type="flex" justify="center">
          <Col xs={22} sm={23} md={22} lg={22} xl={22} xxl={22}>
            <Row type="flex" justify="center" gutter={[20,0]}>
              <Col
                className="radar-desc"
                xs={24}
                sm={24}
                md={16}
                lg={18}
                xl={18}
                xxl={14}
              >
                <img
                  src={primeRadarLogo}
                  alt="PRIME Radar"
                  className="radar-logo"
                />
                <div className="banner-description">
                  Get the latest market insights, opinions, and sentiments from
                  distinguished experts and practitioners in the industry, in
                  our PRIME Radar webinar series.
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
                xxl={6}
                className="radar-cta"
              >
                <br />
                <Link to="/event-schedules">
                  <Button
                    className="btn-view-sched"
                    size="large"
                  
                    style={{ marginTop: 10 }}
                  >
                    <b>VIEW SCHEDULES</b>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </StyledDivWrapper>
  );
}

export default RadarWebinarComponent;
